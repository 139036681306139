import Typography from '../../atoms/typography'
import BHLink from '../../molecules/link'
import { Grid as MuiGrid } from '@mui/material'
import { styled } from '@mui/system'
import BHButton from '../../molecules/button'

const shouldForwardProp = (prop) => {
	return !['hasSiblings', 'aboveTheFold', 'hasVideo'].includes(prop)
}

const Container = styled('div')`
	display: flex;
	flex-direction: column;
	max-width: unset;
	padding: 0;
	margin: ${({ theme }) => theme.spacing(8, 'auto')};
	${({ theme }) => theme.breakpoints.up('sm')} {
		margin: ${({ theme }) => theme.spacing(8, 2.25)};
		max-width: 440px;
	}
	&.countdown-before {
		margin: ${({ theme }) => theme.spacing(0, 'auto')};
		${({ theme }) => theme.breakpoints.up('sm')} {
			max-width: unset;
		}
	}
`

const Title = styled(Typography)`
	margin: auto;
	color: ${({ theme }) => theme.palette.common.black};
	margin-bottom: ${({ theme }) => theme.spacing(3)};
	max-width: 220px;
	${({ theme }) => theme.breakpoints.up('sm')} {
		max-width: unset;
		margin-left: 0;
		margin-right: 0;
	}
`
const Subtitle = styled(Typography)`
	font-size: ${({ theme }) => theme.typography.pxToRem(20)};
	color: ${({ theme }) => theme.palette.common.black};
	margin-bottom: ${({ theme }) => theme.spacing(2)};
	${({ theme }) => theme.breakpoints.up('md')} {
		font-size: ${({ theme }) => theme.typography.pxToRem(24)};
		margin-bottom: ${({ theme }) => theme.spacing(3)};
	}
`
const Description = styled('div')`
	font-size: ${({ theme }) => theme.typography.pxToRem(14)};
	color: ${({ theme }) => theme.palette.primary.main};
	margin-bottom: ${({ theme }) => theme.spacing(4)};
	line-height: ${({ theme }) => theme.spacing(3)};
	.MuiTypography-root {
		font-size: inherit;
		line-height: inherit;
	}
`

const CtaContainer = styled(MuiGrid, { shouldForwardProp })`
	flex-direction: column;
	gap: 22px;
	margin-top: ${({ hasSiblings, theme }) => (hasSiblings ? theme.spacing(0) : '0')};
	${({ theme }) => theme.breakpoints.up('sm')} {
		flex-direction: row;
		gap: 38px;
	}
`

const Link = styled(BHLink)`
	width: 100%;
`

const Button = styled(BHButton)``

export { Container, Title, Subtitle, Description, CtaContainer, Link, Button }
